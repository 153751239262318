<template>
  <div v-if="permission.access">
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <b-card>
          <b-form-group
            label="Select Partner Logo"
            label-for="student_work_title"
          >
            <b-form-file
              multiple
              id="student_work"
              accept=".jpg, .png, .gif"
              @change="addLogo($event)"
              placeholder="Select Icon Image"
              drop-placeholder="Drop file here..."
            />
          </b-form-group>
          <div>
            <div
              class="d-inline-block image_div"
              v-for="(image, index) in show_images"
              :key="index"
              v-if="show_images.length"
            >
              <div class="image_delete" @click="PermanentDeleteImage(image.id)">
                X
              </div>
              <b-img
                :src="image.link"
                height="90"
                width="180"
                class="rounded mr-2 mb-1"
              />
            </div>
            <div
              class="d-inline-block image_div"
              v-for="(image, index) in images"
              :key="index"
              v-if="images.length"
            >
              <div class="image_delete" @click="deleteImage(index)">X</div>
              <b-img
                :src="image"
                height="90"
                width="180"
                class="rounded mr-2 mb-1"
              />
            </div>
          </div>
        </b-card>
        <div class="d-flex justify-content-end mb-5">
          <b-button
            type="button"
            variant="outline-danger"
            class="mr-1"
            @click="back"
          >
            Back
          </b-button>
          <b-button type="submit" variant="primary" @click="validationForm">
            Save
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardTitle,
  BImg,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      required,
      email,
      snowOption: {
        minHeight: 200,
      },
      form: {
        images: [],
      },
      images: [],
      show_images: [],
      permission: {
        access: false,
      },
    };
  },
  mounted() {
    this.Permission();
    this.edit();
  },
  methods: {
    back() {
      this.$router.push("/cit/partner");
    },
    async edit() {
      this.images = [];
      this.show_images = [];
      const course = await this.callApi(
        "post",
        "/app/cit/partner/category/show/" + this.$route.params.id,
        this.search
      );
      if (course.status == 200) {
        course.data.cit_partners.forEach((image) => {
          this.show_images.push({
            id: image.id,
            link: this.$store.state.base_url + image.logo,
          });
        });
      }
    },
    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "cit.partner.create") {
          this.permission.access = true;
        }
      });
    },

    async PermanentDeleteImage(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't to delete it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post("/app/cit/partner/delete/" + id)
            .then((res) => {
              this.s(res.data.message);
              this.show_images = [];

              res.data.images.forEach((image) => {
                this.show_images.push({
                  id: image.id,
                  link: this.$store.state.base_url + image.logo,
                });
              });
            })
            .catch((e) => {});
        }
      });
    },

    deleteImage(index) {
      this.form.images.splice(index, 1);
      this.images.splice(index, 1);
    },

    addLogo(e) {
      e.target.files.forEach((data) => {
        this.form.images.push(data);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.images.push(e.target.result);
        };
        reader.readAsDataURL(data);
      });
    },

    add() {
      const fd = new FormData();
      var self = this;
      if (this.form.images.length) {
        for (let i = 0; i < this.form.images.length; i++) {
          let file = self.form.images[i];
          fd.append("images[" + i + "]", file);
        }
      }

      axios
        .post("/app/cit/partner/add/" + this.$route.params.id, fd)
        .then((res) => {
          this.s(res.data.message);
          this.edit();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.images) {
              this.e(e.response.data.errors.images[0]);
            }
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.image_div {
  position: relative;
}
.image_delete {
  width: 25px;
  height: 25px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 5px;
  right: 30px;
  background: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  border-radius: 50%;
}
</style>
